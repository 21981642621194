.main {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 1em;
    margin-bottom: 2em;
}

.discoveryTopSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.discoveryTitle {
    text-align: center;
}
