.numberInputContainer {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 1px 3px 1px rgba(115, 115, 115, 0.2);
    border-radius: 24px;
    overflow: hidden;
    padding: 0 0 0 4px;
    background-color: white;
    /* font-size: 0.8em; */
    justify-content: space-between;
    width: fit-content;
}

.numberInputContainer:focus-within {
    box-shadow: 0px 1px 0px 2px rgba(115, 115, 115, 1);
}

.numberInputControllerGroup {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    /* width: 100%; */
}

.numberInputController {
    flex: 1;
    justify-content: center;
    font-size: 12px;
    color: rgba(115, 115, 115, 1);
    line-height: 18px;
    width: 20px;
    border: none;
    background-color: white;
    padding-inline-start: 0px;
    padding-inline-end: 8px;
    padding-block: 1px;
    /* padding: 0 4px; */
}

.numberInputController:active {
    background-color: grey;
}

.numberInputDollarIcon {
    font-size: 22px;
    font-style: normal;
    opacity: 64%;
    width: 20px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: white;
    /* margin: 0 8px; */
}

.numberInputBox {
    border: none;
    height: auto;
    width: 36px;
    font-size: 20px;
    text-align: center;
}

.numberInputBoxDollar {
    width: 135px;
    text-align: left;
}

.numberinputTitle {
    font-size: 15px;
    margin: 0 8px;
    color: rgba(55, 55, 55, 0.75);
    display: flex;
    align-items: center;
    background-color: white;
}
