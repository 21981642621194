.container {
    background-color: transparent;
}

.post-display-vertical {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 0px;
    border: none;
    background-color: var(--white);
    overflow: hidden;
    align-items: center;
    height: 100%;
    justify-content: space-between;

}

.media-vertical {
    max-width: 240px;
    max-height: 360px;
    /* aspect-ratio: 1/1; */
    object-fit: cover;
    border-radius: 12px;
}

.post-metrics-date-group-vertical {
    margin: 1em 1em 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-align: left;
}

.post-metrics-vertical {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin: 16px; */
    width: 100%;
    height: 100%;
}

.backdrop {
    background-color: #000;
    opacity: 0.64;
}

.centeredView {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.blurContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--white);
}

.modalTopRow {
    display: flex;
    max-height: 64px;
    padding: 12px 24px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 20px;
}

.linkButton {
    border: 2px solid rgba(125, 125, 125, 1);
    background-color: none;
    height: 36px;
    max-width: 100px;
}

.linkButtonText {
    color: rgba(125, 125, 125, 1);
    font-size: 14px;
}
