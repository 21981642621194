.gradientContainer {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .numText {
    font-weight: 600;
    font-size: 26px;
    color: var(--softBlack);
  }
  
  .titleText {
    font-size: 13px;
    color: var(--softBlack);
    opacity: 0.64;
  }
  