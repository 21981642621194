.post-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.sort-filter-group-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin: 6px 0;
    gap: 1em;
  }
  
  .sort-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgba(255, 255, 255, 0.5); */
    box-shadow: inset 0px -0.5px 0px 1px var(--valBlack);
    border-radius: 36px;
    height: 36px;
    padding: 8px 12px 8px 12px;
    background-color: var(--white);
  }
  
  .order-button {
    display: flex;
    align-items: center;
    font-size: 1em;
    padding: 8px;
    border: none;
    background-color: transparent;
  }
  
  .post-list-wrapper {
    display: flex;
    flex-direction: column;
    margin: 16px 0 32px;
    gap: 8px
  }

  .desktop-post-list-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2em;
    column-gap: 2em;
    margin: 16px 0 32px;
}
  
  .btn-inactive, .btn-active {
    margin: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--defaultGreen);
    border-radius: 40px;
    padding: 8px 16px;
    font-size: 0.8em;
    font-weight: 500;
    border: 1.5px solid var(--defaultGreen);
    background-color: transparent;
    cursor: pointer;
}

.btn-inactive:hover {
    opacity: 92%;
    box-shadow: inset 0px -1.5px 0px 1.5px var(--defaultGreen);
    transform: translate3d(0, -1px, 0);
    transition-duration: 350ms;
}

.btn-active {
    color: var(--white);
    background-color: var(--defaultGreen);
}