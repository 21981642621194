
  
  .passwordInput.focused {
    border-color: var(--white);
  }
  
  .passwordInput.hasErrors {
    border-color: #f55;
  }
  
  .errorMessages {
    color: var(--flashyPink);
    margin-top: 10px;
    font-size: .9em;
  }

  .pwRequirements.pwRequirements {
    display: flex;
    flex-direction: column;
    gap: 1px;
    font-size: 0.9em
  }
  