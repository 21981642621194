.clickableCampaignOffer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 10px;
    background-color: var(--white);
    border-radius: 42px;
    gap: 16px;
    transition-duration: 200ms;
}

.clickableCampaignOffer:hover {
    box-shadow: inset 0px -1px 0px 2px var(--valBlack);
    offset: 2px 2px;
    transform: translate3d(0, -0.3em, 0);
    transition-duration: 450ms;
}

.campaignOfferContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px 10px;
    background-color: var(--white);
    border-radius: 42px;
    gap: 16px;
    transition-duration: 450ms;
}

.campaignOfferPic {
    height: 4em;
    width: 4em;
    border-radius: 50px;
    object-fit: cover;
}

.topCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.6em;
}

.topCard {
    cursor: pointer;
}

.topCardFlexBoxClickable {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
}

.picTitle {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.campaignOfferMetrics {
    display: flex;
    width: 100%;
    max-width: 600px;
    justify-content: space-around;
    align-items: center;
}

.titleDateStatusContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.postCountText {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
}

.postCount{
    font-size: 13px;
    font-weight: 600;
}

.titleCard {
    display: flex;
    align-items: center;
    flex-grow: 2;
}

.title {
    font-size: 1.1em;
    font-weight: 600;
    margin-left: 6px;
    max-width: 100%;
}

.linkButton {
    height: 0px;
    margin: 0 4px;
}

.viewReportButton {
    display: flex;
    gap: 6px;
    margin: 0 2px;
    background-color: transparent;
    border: none;
    font-size: 0.65em;
    cursor: pointer;
    border-radius: 8px;
    padding: 3px 7px;
    box-shadow: inset 0px 0px 0px 1px var(--softBlack);
    transition-duration: 250ms;
}
  
.viewReportButton:hover {
    background-color: var(--softBlack);
    color: var(--white);
    transition-duration: 250ms;
    /* box-shadow: inset 0px 0px 0px 1.5px var(--softBlack) */
}

.dateStatus {
    display: flex;
    gap: 1em;
    align-items: center;
}

.dateCard {
    font-size: 0.7em;
    color: var(--valGrey);
}

.statusCard {
    font-size: 0.6em;
    font-weight: 500;
    padding: 3px 6px;
    border-radius: 4px;
    color: var(--white);
    background-color: var(--color);
}

.biography {
    display: flex;
    color: rgba(120, 120, 120, 1);
    padding: 0 8px 8px;
}

.bottomCard {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 1em;
}

.chartButtonGroup {
    display: block;
    margin: -1em 0 0;
}

.chartButton {
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color);
    border-radius: 40px;
    padding: 5px 10px;
    font-size: 0.75em;
    font-weight: 500;
    border: 1.5px solid var(--color);
    background-color: transparent;
    white-space: nowrap;
}

.chartButton:hover {
    cursor: pointer;
    opacity: 92%;
    box-shadow: inset 0px -1px 0px 1px var(--color);
    transition-duration: 350ms;
}

.activeChartTab {
    color: var(--white);
    background-color: var(--color);
}

.activeChartTab:hover {
    box-shadow: none;
}

.chartContainer {
    display: flex;
    width: 100%;
}

.costMetrics {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 2em;
    min-width: 110px;
}

.metricsAndDropdownButton {
    display: flex;
    gap: 1em;
    width: 100%;
    align-items: center;
    margin: 0 8px 0 0
}

@media (max-width: 600px) {
    .title {
        font-size: 1em;
    }

    .profileIcon {
        font-size: 1.25em;
    }

    .topCard {
        gap: 2px;
        flex-wrap: wrap;
    }

    .picTitle {
        gap: 0.5em;
    }

    .campaignPic {
        height: 3em;
        width: 3em;
    }

    .campaignOfferMetrics {
        margin: 1.5em 0 1em;
    }

    .bottomCard {
        flex-direction: column;
        gap: 0;
    }

    .chartButtonGroup {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        gap: 0.5em;
        margin: 0;
        order: 2;
    }

    .chartButton {
        margin: 4px;
    }

    .chartContainer {
        order: 1;
    }

    .costMetrics {
        flex-direction: row;
        gap: 2em;
        margin: 1em 0 0.5em;
        width: 100%;
        order: 3;
    }

    .metricsAndDropdownButton {
        margin: 0
    }
}