.segmented-buttons {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  
.segmented-button {
    margin: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--softBlack);
    border-radius: 40px;
    padding: 10px 20px;
    font-size: 0.9em;
    border: 1px solid var(--softBlack);
    background-color: transparent;
    transition-duration: 250ms;
}

.segmented-button:hover {
    cursor: pointer;
    opacity: 92%;
    box-shadow: inset 0px -2px 0px 2px var(--valBlack);
    transform: translate3d(0, -1px, 0);
    transition-duration: 350ms;
}

.active-segment {
    color: var(--white);
    background-color: var(--softBlack);
}

.active-segment:hover {
    box-shadow: none;
}

.button-icon {
    /* height: 16px;
    width: 16px; */
}
  