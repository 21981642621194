.bouncing-balls-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .ball {
    width: 20px;
    height: 20px;
    margin: 10px auto;
    border-radius: 50px;
  }
  
  .ball:nth-child(1) {
    background: var(--softPink);
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
  }
  
  .ball:nth-child(2) {
    background: var(--softPink);
    -webkit-animation: left 1.1s infinite ease-in-out;
    -moz-animation: left 1.1s infinite ease-in-out;
    animation: left 1.1s infinite ease-in-out;
  }
  
  .ball:nth-child(3) {
    background: var(--softPink);
    -webkit-animation: right 1.05s infinite ease-in-out;
    -moz-animation: right 1.05s infinite ease-in-out;
    animation: right 1.05s infinite ease-in-out;
  }
  
  .ball:nth-child(4) {
    background: var(--softPink);
    -webkit-animation: left 1.15s infinite ease-in-out;
    -moz-animation: left 1.15s infinite ease-in-out;
    animation: left 1.15s infinite ease-in-out;
  }
  
  .ball:nth-child(5) {
    background: var(--softPink);
    -webkit-animation: right 1.1s infinite ease-in-out;
    -moz-animation: right 1.1s infinite ease-in-out;
    animation: right 1.1s infinite ease-in-out;
  }
  
  .ball:nth-child(6) {
    background: var(--softPink);
    -webkit-animation: left 1.05s infinite ease-in-out;
    -moz-animation: left 1.05s infinite ease-in-out;
    animation: left 1.05s infinite ease-in-out;
  }
  
  .ball:nth-child(7) {
    background: var(--softPink);
    -webkit-animation: right 1s infinite ease-in-out;
    -moz-animation: right 1s infinite ease-in-out;
    animation: right 1s infinite ease-in-out;
  }
  
  @-webkit-keyframes right {
    0% {
      -webkit-transform: translate(-16px);
    }
    50% {
      -webkit-transform: translate(16px);
    }
    100% {
      -webkit-transform: translate(-16px);
    }
  }
  
  @-webkit-keyframes left {
    0% {
      -webkit-transform: translate(16px);
    }
    50% {
      -webkit-transform: translate(-16px);
    }
    100% {
      -webkit-transform: translate(16px);
    }
  }
  
  @-moz-keyframes right {
    0% {
      -moz-transform: translate(-16px);
    }
    50% {
      -moz-transform: translate(16px);
    }
    100% {
      -moz-transform: translate(-16px);
    }
  }
  
  @-moz-keyframes left {
    0% {
      -moz-transform: translate(16px);
    }
    50% {
      -moz-transform: translate(-16px);
    }
    100% {
      -moz-transform: translate(16px);
    }
  }
  
  @keyframes right {
    0% {
      transform: translate(-16px);
    }
    50% {
      transform: translate(16px);
    }
    100% {
      transform: translate(-16px);
    }
  }
  
  @keyframes left {
    0% {
      transform: translate(16px);
    }
    50% {
      transform: translate(-16px);
    }
    100% {
      transform: translate(16px);
    }
  }