.alertButton {
    border: none;
    border-radius: 8px;
    padding: 6px 10px;
    cursor: pointer;
    transition-duration: 150ms;
}

.alertButton:hover {
    box-shadow: outset 1px 2px 3px rgba(55, 55, 55, 0.8);
    transition-duration: 150ms;
}

.delete {
    background-color: var(--valRed);
    color: var(--white);
    font-weight: 600;
}

.cancel {
    background-color: var(--white);
    color: var(--softBlack)
}