.container {
    backdrop-filter: blur(4px);
    height: calc(100vh - 32px);
    max-height: 650px;
    box-shadow: 1px 1px 6px 1px rgba(55, 55, 55, 0.2);
    margin-top: 16px;
    margin-bottom: 16px;
    z-index: 900;
}

.menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 52px);
    max-height: calc(650px - 20px);
    padding: 10px 0;
}

.linkIcon {
    color: #1E1E1E;
    font-weight: bold;
    padding: 8px;
    aspect-ratio: 1/1;
}

.activeLinkIcon {
    color: var(--white);
    background-color: var(--softBlack);
    padding: 8px;
    border-radius: 8px;
    aspect-ratio: 1/1;
}

.linkTitle {
    color: black;
    font-weight: 500;
}

.submenu {
    background-color: var(--softBlack);
    color: var(--white);
    border-radius: 25px;
    border: 1px solid var(--beige)
}

.sublinkTitle {
    color: var(--white);
    font-weight: 400;
    font-size: medium;
}