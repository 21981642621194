.standardTextInput {
    color: var(--white);
    border-radius: 16px;
    border: 1.5px solid rgba(155, 155, 155, .6);
    /* padding: 10px; */
  }
  
  .standardTextInput.focused {
    border-color: var(--white);
  }
  