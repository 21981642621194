option {
    background-color: var(--white);
    border-radius: 4px;
}

h1 {
    font-family: 'Lora';
    font-weight: 500;
}

h2 {
    font-family: 'Lora';
    font-weight: 500;
}

h3 {
    font-family: 'Lora';
    font-weight: 500;
}

div {
    outline: none
}

.flex-row {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea:focus, input:focus{
    outline: none;
}

.borderRed {
    border: 1px solid red;
}

.borderBlue {
    border: 1px solid blue;
}