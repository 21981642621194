.numTextTSC {
    font-weight: 500;
    font-size: 1.3em;
    margin-bottom: 2px;
  }
  
  .titleTextTSC {
    font-weight: 400;
    font-size: 0.7em;
    opacity: 0.72;
  }
  
  .topTitleTextTSC {
    font-weight: 500;
    font-size: 0.7em;
    opacity: 0.8;
    border-radius: 4px;
    padding: 1px 6px 2px;
    margin: 1px 0;
    color: var(--white);
    width: fit-content;
  }
  
  @media (max-width: 900px) {
    .numTextTSC {
      font-size: 1.1em;
    }
    .titleTextTSC {
      font-size: 0.7em;
    }
    .topTitleTextTSC {
      font-size: 0.7em;
      padding: 1px 4px 2px;
    }
  }