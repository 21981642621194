.metrics-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.metrics-sub-container-desktop {
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.metrics-sub-container-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.flex-row-title {
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    align-items: center;
    justify-content: space-between;
    margin: 0.5em 1em;
}

.smallerSegment {
    padding: 8px 12px;
    font-size: 0.8em;
    font-weight: 500;
}

.chart-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    margin: 8px 0;
}

.chart-button {
    margin: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--color);
    border-radius: 40px;
    padding: 6px 12px;
    font-size: 0.8em;
    font-weight: 500;
    border: 1.5px solid var(--color);
    background-color: transparent;
    transition-duration: 250ms;
}

.chart-button:hover {
    cursor: pointer;
    opacity: 92%;
    box-shadow: inset 0px -1.5px 0px 1.5px var(--color);
    transform: translate3d(0, -1px, 0);
    transition-duration: 350ms;
}

.active-chart-tab {
    color: var(--white);
    background-color: var(--color);
}

.active-chart-tab:hover {
    box-shadow: none;
}

.chart-container {
    border-radius: 12px;
    padding-bottom: 1em;
    background-color: var(--white);
}

.indicator {
    position: relative;
    height: 0;
    width: 100%;
    left: 0;
    transition: left 0.2s ease-out;
}

.chart {
    background-color: lightgray;
}

.chart-post-display-group {
    display: flex;
    gap: 1em;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em 0 0;
}

.report-card-rows-container-desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
    justify-content: space-between;
}

.report-card-rows-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    gap: 1em;
    margin-bottom: 1.5em;
}

.report-card-row {
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    overflow: hidden;
    justify-content: space-between;
    gap: 8px;
    color: black;
}

.left-card {
    background-color: var(--white);
    padding: 1em;
}

.right-card {
    background-color: var(--white);
    padding: 1em;
}

@media (max-width: 600px) {
    .flex-row-title {
        flex-direction: column;
        align-items: flex-start;
        margin: 1em 1.5em;
    }
    .chart-button-group {
        margin: 0 0 6px 0;
        width: 100%;
        justify-content: space-between;
    }
    .chart-button {
        padding: 5px 10px;
    }
}
