.background {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5em;
    border-radius: 20px;
    background-color: var(--white);
    max-height: 92%;
    width: clamp(320px, 35%, 500px);
    gap: 2em;
}

.title {
    font-family: 'Lora';
    font-size: 1.2em;
    font-weight: 500;
    white-space: nowrap;
}

.subText {
    font-size: 0.8em;
    font-weight: 300;
}

.profileSettings {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}

.profileSubSection {
    display: flex;
    gap: 1.5em;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.profileInputs {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}

.imageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
}

.customUploadButton {
    display: inline-block;
    padding: 10px;
    background-color: #555;
    font-size: 0.75em;
    color: white;
    cursor: pointer;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    border-radius: 12px;
}

.imagePlaceholder {
    width: 100px;
    height: 100px;
    border: dashed 3px grey;
    border-radius: 64px;
    margin-bottom: 10px;
}

.imagePreview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 64px;
}

.campaignName .MuiOutlinedInput-root fieldset {
    border-radius: 40px !important;
}

.loader {
    aspect-ratio: 3/1;
}

.error {
    color: var(--flashyPink);
    font-size: 0.7em;
    text-align: center;
    margin-top: 4px;
}

.unclickableButton {
    opacity: 0.64;
}

.unclickableButton:hover {
    background-color: var(--balancedCyan);
}
