.mainSearchBar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(250, 250, 250, 1);
  padding: 8px 8px;
  border: 1.5px solid #c8c8c8;
  border-radius: 32px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: clamp(320px, 40%, 600px);
}

.searchBarAtHandle {
  margin-left: 6px;
  margin-right: 2px;
  font-size: 1.3em;
  line-height: 1.2em;
  font-weight: 600;
  opacity: 0.48;
}

.searchBar input {
  flex: 1;
  font-size: 1.2em;
}

.searchBar button {
  padding: 10px;
  background-color: var(--softBlack);
  color: var(--white);
}

.searchBar button:hover {
  opacity: 80%;
  color: var(--softBlack);
  box-shadow: inset 0 0 0 3px rgba(46, 46, 46, 0.2);
}






.searchBarSmall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(250, 250, 250, 1);
  padding: 4px 4px;
  border: 1.5px solid #c8c8c8;
  border-radius: 32px;
  margin: 12px;
  max-width: 140px;
}

.searchBarAtHandleSmall {
  margin-left: 4px;
  margin-right: 2px;
  font-size: 1em;
  line-height: 0.8em;
  font-weight: 600;
  opacity: 0.48;
}

.searchBarSmall input {
  flex: 1;
  font-size: 0.9em;
  margin: 0 4px
}

.searchBarSmall button {
  padding: 6px;
  background-color: var(--softBlack);
  color: var(--white);
}

.searchBarSmall button:hover {
  opacity: 80%;
  color: var(--softBlack);
  box-shadow: inset 0 0 0 3px rgba(46, 46, 46, 0.2);
}

.segementedButtonSmall {
  padding: 6px;
  border-radius: 10px;
}

.segementedButtonSmall:hover {
  box-shadow: inset 0px -1px 0px 1px var(--valBlack);
}