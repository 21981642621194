.clickableSocialHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
    background-color: var(--white);
    border-radius: 42px;
    gap: 16px;
    transition-duration: 200ms;
}

.clickableSocialHeader:hover {
    cursor: pointer;
    box-shadow: inset 0px -2px 0px 3px var(--valBlack);
    offset: 2px 2px;
    transform: translate3d(0, -0.25em, 0);
    transition-duration: 450ms;
 }

.socialProfileHeaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: transparent;
    border-radius: 42px;
    gap: 16px;
    margin-top: 8px;
}

.profilePic {
    height: 4em;
    width: 4em;
    border-radius: 50px;
}

.topCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.6em;
    /* border: 1px purple solid */
}

.topCardFlexBoxClickable {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    /* border: 1px red solid */
}

.topCardFlexBox {
    display: flex;
    padding: 0.7em;
    padding-right: 1em;
    background-color: var(--white);
    border-radius: 50px;
    /* border: 1px red solid */
}

.picHandle {
    display: flex;
    gap: 1em;
}

.socialProfileHeaderMetrics {
    display: flex;
    width: 100%;
    max-width: 600px;
    justify-content: space-around;
    margin: 1em 0 0.4em;
}

.textCard {
    display: flex;
    align-items: center;
    flex-grow: 2;
}

.handle {
    font-size: 1.4em;
    font-weight: 600;
    margin-left: 8px;
}

.linkButton {
    height: 0px;
}

.heart {
    padding: .5em;
    color: var(--defaultGreen);
}

.biography {
    display: flex;
    color: rgba(120, 120, 120, 1);
    padding: 0 8px 8px;
}

@media (max-width: 600px) {
    .handle {
        font-size: 1.1em;
    }
    .profileIcon {
        font-size: 1.25em;
    }
    .topCard {
        gap: 0;
    }
    .picHandle {
        gap: 0.5em;
    } 
    .profilePic {
        height: 3em;
        width: 3em;
    }
    .heart {
        margin: 8px;
        padding: 0;
    }
}