.linkButton {
    color: var(--balancedCyan);
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    padding: 8px;
    font-size: 1em;
    gap: 6px;
}

.linkButton:hover {
    cursor: pointer;
    text-decoration: underline;
}

.linkIcon {
    color: var(--balancedCyan);
    height: 12px;
    width: 12px;
}