body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--beige);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Lora";
  font-weight: 100 400 500 600 1000;
  src: local("Lora"),
   url("./Assets/Fonts/Lora.ttf") format("truetype");
 }

 @font-face {
  font-family: "Inter";
  font-weight: 100 400 500 600 1000;
  src: local("Inter"),
   url("./Assets/Fonts/Inter.ttf") format("truetype");
 }

/* Added less clunky scrollbar (vs Windows scrollbar) that works for Chome, Edge, Safari and Opera, BUT NOT Firefox*/
::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: var(--beige);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(55, 55, 55, 0.32);
  border-radius: 20px;
  border: 5px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(55, 55, 55, 0.64);
}