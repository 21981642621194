.datePickerContainer {
    display: flex;
    gap: 12px;
    align-items: center;
}

.datePickerButton {
    box-shadow: 0px 1px 3px 1px rgba(115, 115, 115, 0.2);
    border-radius: 24px;
    border: none;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    color: rgba(55, 55, 55, 0.75);
    padding: 12px;
    width: clamp(50px, 100%, 250px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 0px solid rgba( 55, 55, 55, 0.25); */
    transition-duration: 200ms;
    height: 40px;
}

.datePickerButton:hover {
    box-shadow: 0px 1px 3px 1px rgba(115, 115, 115, 0.4);
    transition-duration: 300ms;
}

.datePickerButton:focus-within {
    box-shadow: 0px 1px 0px 2px rgba(115, 115, 115, 1);
}