:root {
    --valBackground: #F9F6F4;
    --valBorder: #DFE0EB;
	--valGrey: rgb(149, 152, 165);
	--valOrange: rgba(245,195,120,1);
	--valGreen: rgba(99,209,110,1);
	--valBlack: rgba(54,55,64,1);
	--valRed: rgba(211, 72, 72, 0.72);
	--valDarkPurple: rgba(140,124,155,1);
	--valLightPurple: rgba(177,142,209,1);
    --valDarkBlue: rgba(0, 164, 216, 0.8);
	--valLightBlue: rgba(107,202,253,1);
	--defaultGreen: rgba(111,174,156,1.0);
    --handsomeBlue: rgba(97,130,161,1.0);
    --flashyPink: #E680A5;
    --darkPink: rgba(152,77,105,1.0);
    --softPink: #F6C0C0;
    --blackBG: #2D2D2F;
    --softBlack: #2E2E2E;
    --valuenceBlack: #363740;
    --nicePurple: #CDABBA;
    --white: #FAFAFA;
    --balancedCyan: #68989E;
    --darkCyan: rgba(64,115,135,1.0);
    --darkGreen: rgba(57,87,78,1.0);
    --babyBlue: rgba(223, 255, 255, 1);
    --beige: #F2EEE9;
    --darkBeige: #e9e4de;
    --transparentGrey: rgba(75, 75, 75, 0.24);
    --lighterTransparentGrey: rgba(75, 75, 75, 0.12);
}