.mainButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    border: 0px;
    box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.1);
    height: 40px;
    padding: 8px 30px;
    background-color: var(--balancedCyan);
    color: var(--white);
    font-size: 1em;
    transition-duration: 300ms;
}

.mainButton:hover {
    background-color: var(--softBlack);
    cursor: pointer;
    transition-duration: 300ms;
}

.googleButton {
    background-color: var(--white);
    color: var(--softBlack);
}

.googleButton:hover {
    background-color: var(--softBlack);
    color: var(--white);
}

.googleIcon {
    height: 20px;
    width: 20px;
    margin-right: 20px;
}

.icon {
    height: 20px;
    width: 20px;
    margin-left: -8px;
    margin-right: 8px;
}