.videoContainer,
.imageContainer {
    position: relative;
    width: 100%;
    height: 60vh;
}

.backgroundVideo,
.image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.muteButtonContainer,
.captionButtonContainer {
    position: absolute;
    bottom: 12px;
    z-index: 3;
}

.muteButtonContainer {
    right: 12px;
}

.captionButtonContainer {
    top: 12px;
    left: 12px;
    z-index: 2;
}

.buttonIcon {
    color: white;
    background-color: rgba(55, 55, 55, 0.5);
    padding: 8px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
}

.captionContainer {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
}

.captionText {
    font-size: 15px;
    margin: 24px;
}
