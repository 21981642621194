.mainHome {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 2em;
    margin-bottom: 2em;
}

.homeTopSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 2em
}

.homeTitle {
    text-align: center;
}

.logo {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin-top: 1em;
}
