.loginPage {
    flex-direction: column;
    display: flex;
    width: clamp(300px, 92%, 400px);
    box-shadow: none !important;
    justify-content: flex-start;
    align-items: center;
    gap: 12vh;
    margin: 24px 0;
}

.logo {
    display: flex;
    justify-content: center;
    width: fit-content;
}

.main-login {
    display: flex;
    flex-direction: column;
    width: clamp(300px, 92%, 400px);
    gap: 8vh;
}