.mainCreator {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1300px;
    align-items: center;
}

.reportContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2em;
}

.backButton {
    display: flex;
    gap: 6px;
    font-size: large;
    background-color: transparent;
    align-items: center;
    border: none;
    color: var(--darkCyan);
    cursor: pointer;
    margin: 8px 0 16px
}

.loaderContainer {
    display: flex;
    height: 100%;
    margin-top: -3em;
    margin-bottom: 3em;
    flex-direction: column;
    align-items: center;
}
