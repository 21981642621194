.offerBuilderTextWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
    background-color: var(--white);
    border-radius: 12px;
    gap: 1em;
    width: calc(100% - 2em);
}

.offerBuilder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    gap: 5em;
}

.offerBuilderInput {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0em;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    justify-content: space-between;
}

.inputRow {
    display: flex;
    gap: 1em;
}

.inputColumn, .inputColumnButton {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.5em;
    width: 100%;
}

.MuiOutlinedInput-notchedOutline {
    border-width: 0;
    padding: 0
}

.pricingOfferCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--defaultGreen);
    border-radius: 12px;
    overflow: hidden;
    width: calc(100%);
}

.pricingOfferTotalContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    width: 40%;
    height: calc(100% - 20px);
    gap: 16px;
    padding: 10px;
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
}


.pricingOfferTotalValue {
    font-size: 2em;
}

.pricingOfferCardContainerSub {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 12px;
    padding: 10px;
    width: 30%;
    min-height: max-content;
    font-size: 0.8em;
    font-weight: 200;
}

.metricsImp {
    background-color: var(--flashyPink);
    border-radius: 12px 0 0 12px;
}

.metricsEng {
    background-color: var(--handsomeBlue);
    border-radius: 12px;
    margin-left: -12px
}

.mainButtonOffer {
    height: 36px;
    min-width: 100%;
    padding: 8px 20px;
}

.mainButtonOfferSaved, .mainButtonOfferSaved:hover {
    height: 36px;
    min-width: 100%;
    padding: 8px 20px;
    opacity: 0.64;
    cursor: auto;
    background-color: var(--balancedCyan);
}

.descriptionText {
    color: rgba(20, 20, 20, 0.8);
    font-weight: 300;
    display: block;
    margin: 0 0 6px 0;
}

.warningText {
    font-size: 0.8em;
}

@media (max-width: 1050px) {
    .offerBuilder,
    .inputContainer {
        flex-direction: column;
        gap: 1.5em;
    }

    .offerBuilderInput,
    .inputColumn {
        width: 100%;
    }
    .inputColumnButton {
        flex-direction: row;
    }
    .pricingOfferCard {
        order: 1;
    }
    .descriptionText {
        order: 2;
        margin: 6px 0 0 0;
    }
}

@media (max-width: 800px) {
    .pricingOfferTotalValue {
        font-size: 1.5em;
    }
    .pricingOfferTotalContainer {
        font-size: 15px;
        width: 35%;
    }
    .pricingOfferCardContainerSub {
        width: 32.5%;
    }
}

.blur {
    filter: blur(10px);
}