/*
FONT-SIZE LOGIC:

body/Smallest < Small < Regular < MedLarge < Large < Largest
Any font-size outisde of these properties can be customized directly in the HTML tag


FONT-WEIGHT LOGIC:

body/RegWeight < Medium < SemiBold < Bold
Any font-weight outisde of these properties can be customized directly in the HTML tag

*/

body {
    font-size: 1rem;
    font-family: "Inter";
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0.1px;
	text-decoration: none;
	text-transform: none;
}

.smallBody {
    font-size: 0.7em;
    color: rgba(115, 115, 115, 1)
}

.section-title {
    font-family: 'Lora';
    font-size: 1.5em;
    font-weight: 500;
    white-space: nowrap;
}

.section-subtitle {
    font-family: 'Lora';
    font-size: 1.2em;
    font-weight: 500;
    white-space: nowrap;
}

.veryLargeText {
    font-size: 2.5em;
    font-weight: 400;
}

.valTextSmallest {
    font-size: 0.75rem;
}
.valTextSmall {
	font-size: 0.875rem;
}
.valTextRegular {
	font-size: 1rem;
}
.valTextMedium {
    font-size: 1.125rem;
}

.valTextRegWeight {
    font-weight: 400;
}
.valTextMedium {
    font-weight: 500;
}
.valTextSemiBold {
    font-weight: 600;
}
.valTextBold {
    font-weight: 700;
}