.numTextTSC {
  font-weight: 500;
  font-size: 1.1rem;
}

.titleTextTSC {
  font-weight: 400;
  font-size: 0.7rem;
  opacity: 0.72;
}

.topTitleTextTSC {
  font-weight: 500;
  font-size: 0.7rem;
  opacity: 0.8;
  border-radius: 4px;
  padding: 2px 5px;
  margin-bottom: 1px;
  color: var(--white);
  width: fit-content;
}

@media (max-width: 900px) {
  .numTextTSC {
    font-size: 0.9rem;
  }
  .titleTextTSC {
    font-size: 0.7rem;
  }
  .topTitleTextTSC {
    font-size: 0.7rem;
    padding: 1px 4px 2px;
  }
}