.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    gap: 0.6em;
    padding: 6px;
    cursor: pointer;
    border-radius: 0;
    /* border: 1px purple solid */
}

.card:hover {
    background-color: rgba(55, 55, 55, 0.08);
}

.picHandle {
    display: flex;
    gap: 6px;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.profilePic {
    height: 1.8em;
    width: 1.8em;
    border-radius: 50px;
}

.textCard {
    display: flex;
    align-items: center;
    flex-grow: 2;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.handle {
    font-size: 0.9em;
    font-weight: 400 !important;
    margin-left: 4px;
}

.profileIcon {
    font-size: 0.85em;
}

.unselectIcon {
    font-size: 1em;
    opacity: 0;
    margin-right: 8px;
}

.card:hover > .unselectIcon {
    opacity: 1;
    transition-duration: 250ms;
}