.campaignListPageContainer {
    display: flex;
    flex-direction: column;
    max-width: 1300px;
    width: 100%;
}

.campaignListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.campaignListContainerTopRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 1em;
}

.campaignInputTopRow {
    display: flex;
    gap: 1em;
}

.campaignSortFilterGroupContainer {
    display: flex;
    width: fit-content;
    gap: 3em;
    align-items: center;
    margin: 6px 0;
    padding: 0 4px 0 0;
    border-radius: 40px;
    background-color: var(--white);
}

.campaignSortContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    box-shadow: inset 0px -0.5px 0px 1px var(--valBlack);
    border-radius: 40px;
    height: 36px;
    padding: 8px 10px 8px 12px;
    font-size: 0.8em;
}

.campaignOrderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    font-size: 0.8em;
    min-width: 120px;
}

.campaignOrderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    height: 20px;
    width: 20px;
    padding: 2px;
    margin: 0 12px 0 0;
    border: none;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--valuenceBlack);
}

.campaignListContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    gap: 1.5em
}

@media (max-width: 1050px) {
    .campaignListContainerTopRow {
        flex-direction: column;
        align-items: flex-start;
    }
}