.background {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    border-radius: 20px;
    background-color: var(--white);
    max-height: 92%;
    max-width: 80%;
    width: fit-content;
    gap: 1.5em;
}

.title {
    font-family: 'Lora';
    font-size: 1.25em;
    font-weight: 500;
    white-space: nowrap;
}

.subText {
    font-size: 0.8em;
    font-weight: 300;
}

.profileInputs {
    display: flex;
    flex-direction: column;
    gap: 2em;
    width: 100%;
}

.selectReportShowcase {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2em;
}

.socialProfileHeaderMetrics {
    display: flex;
    width: 100%;
    max-width: 600px;
    justify-content: space-between;
}


.mainButtonRow {
    display: flex;
    gap: 2em;
    width: 100%;
    justify-content: space-between;
}


@media (max-width: 1050px) {
    .selectReportShowcase {
        flex-direction: column;
    }
}

@media (max-width: 650px) {
    .background {
        align-items: flex-start;
        margin-top: 12%;
    }
    .modalContainer {
        min-width: calc(90% - 1em);
        padding: 1em;
    }
    .mainButtonRow {
        flex-direction: column;
        gap: 1em;
    }
}