.campaignOfferListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
    gap: 1.5em
}

.listContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 32px;
    gap: 1em
}

.campaignOfferListContainerTopRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
    margin-top: 1em;
}

.campaignOfferTitleAndSearch {
    display: flex;
    gap: 1em;
    align-items: center;
}

.campaignInputTopRow {
    display: flex;
    align-items: center;
    gap: 1em;
}

.campaignOfferListSortFilterGroupContainer {
    display: flex;
    width: fit-content;
    gap: 1em;
    align-items: center;
    padding: 0 4px 0 0;
    border-radius: 40px;
    background-color: var(--white);
}

.campaignOfferListSortContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    box-shadow: inset 0px -0.5px 0px 1px var(--valBlack);
    border-radius: 40px;
    height: 36px;
    padding: 8px 10px 8px 12px;
    font-size: 0.8em;
}

.campaignOfferListOrderContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    font-size: 0.8em;
    min-width: 120px;
}

.campaignOfferListOrderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
    height: 20px;
    width: 20px;
    padding: 2px;
    margin: 0 12px 0 0;
    border: none;
    border-radius: 5px;
    color: var(--white);
    background-color: var(--valuenceBlack);
}

@media (max-width: 600px) {
    .campaignOfferListContainerTopRow,
    .campaignOfferTitleAndSearch {
        gap: 1em;
        width: 100%;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
    }
}