.report-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.report-list-container-top-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.report-sort-filter-group-container {
  display: flex;
  width: fit-content;
  gap: 3em;
  align-items: center;
  margin: 6px 0;
  padding: 0 4px 0 0;
  border-radius: 40px;
  background-color: var(--white);
}

.report-sort-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
    box-shadow: inset 0px -0.5px 0px 1px var(--valBlack);
  border-radius: 40px;
  height: 36px;
  padding: 8px 10px 8px 12px;
  font-size: 0.8em;
}

.report-order-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
  font-size: 0.8em;
  min-width: 120px;
}

.report-order-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8em;
  height: 20px;
  width: 20px;
  padding: 2px;
  margin: 0 12px 0 0;
  border: none;
  border-radius: 5px;
  color: var(--white);
  background-color: var(--valuenceBlack);
}

.report-list-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 1.5em
}

.searchHistory {
  display: flex;
  gap: 6px;
  background-color: transparent;
  border: none;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 12px;
  box-shadow: inset 0px 0px 0px 1px var(--softBlack)
}

.searchHistory:hover {
  background-color: var(--white);
  box-shadow: none
}

.searchHistoryActive {
  display: flex;
  gap: 6px;
  border: none;
  font-size: 0.8em;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 12px;
  color: var(--white);
  background-color: var(--softBlack);
}


@media (max-width: 600px) {
  .report-list-container-top-row {
    flex-direction: column;
    align-items: flex-start;
  }
  .searchHistory:hover {
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 1px var(--softBlack)
  }
  
}