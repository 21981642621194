.SignUpOption {
    display: flex;
    flex-direction: column;
    gap: 4vh;
}

.text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #FAFAFA;
  }