.container {
    background-color: transparent;
}

.post-display {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0px;
    border: none;
    background-color: var(--white);
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    transition-duration: 350ms;
}

.post-display:hover {
    transform: translate3d(0, -3px, 0);
    transition-duration: 350ms;
}

.media {
    width: 30vw;
    max-width: 160px;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.postMetricsDateGroup {
    padding: 2em;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 2em;
    text-align: left;
}

.postMetrics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* margin: 16px; */
    width: 100%;
    height: 100%;
}

.backdrop {
    background-color: #000;
    opacity: 0.64;
}

.centeredView {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.blurContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--white);
}

.modalTopRow {
    display: flex;
    max-height: 64px;
    justify-content: space-between;
    align-items: center;
    width: 92%;
    border-radius: 20px;
}

.linkButton {
    background-color: none;
    height: 36px;
    max-width: 100px;
    text-wrap: nowrap;
    border: none;
    font-size: 14px;
    padding: 8px 12px;
}

@media (max-width: 850px) {
    .postMetricsDateGroup {
        padding: 20px;
    }
}
  