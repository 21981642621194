.autocompleteRoot div.MuiOutlinedInput-root {
    border-radius: 40px;
}

.autocompleteRoot div.MuiOutlinedInput-root.Mui-focused {
    /* border-radius: 20px 20px 0px 0px !important; */
    border-radius: 20px !important;
}

.autocompleteRoot div.Mui-focused fieldset {
    border-width: 0 !important;
    border-color: transparent transparent #6F6F6F !important;
}

.autocompleteListbox {
    background-color: #FFF;
}

.autocompleteListbox::-webkit-scrollbar-track {
    background-color: #FFF;
}

.autocompletePopper > div {
    /* border-top-right-radius: 0px;
    border-top-left-radius: 0px; */
    margin: 6px 0 8px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 3px 5px 3px rgba(0, 0, 0, 0.16);
}