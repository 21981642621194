.discovery-card {
    cursor: pointer;
    position: relative;
    /* height: 35vh; */
    display: flex;
    aspect-ratio: 1/1.5;
    border-radius: 8px;
    /* overflow: hidden; */
}

.discovery-card:hover:before {
    background-position: 100% 100%;
    transform: scale(1.08, 1.03);
    box-shadow: inset 0px -2px 0px 4px var(--valBlack);
}

.discovery-card:hover>.discovery-card-background {
    background-position: -15% 0%;
}

.discovery-card:hover>.discovery-card-background {
    opacity: 1;
    transition: opacity 0ms, transform 200ms cubic-bezier(.90, .06, .15, .90);
}

.discovery-card:hover>.discovery-card-background-container {
    opacity: 1;
    background-position: -15% 0%;
    transition: opacity 650ms, transform 1450ms cubic-bezier(.90, .06, .15, .90);
}

.discovery-card:before {
    background: linear-gradient(130deg,
            transparent 0% 33%,
            var(--valuenceBlack) 66%,
            var(--softBlack) 83.5%,
            var(--softBlack) 100%);
    background-position: 0% 0%;
    background-size: 300% 300%;
    content: "";
    height: 100%;
    left: 0px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    transition: background-position 350ms ease, transform 350ms ease;
    width: 100%;
    z-index: 1;
    border-radius: 8px;
}

.discovery-card-background {
    background-image: radial-gradient(rgba(255, 255, 255, 0.18) 8%,
            transparent 8%);
    background-position: 0% 0%;
    background-size: 5vmin 5vmin;
    height: calc(100%);
    padding: 0;
    position: relative;
    transition: background-position 350ms ease;
    width: calc(100%);
    z-index: 2;
}

.discovery-card:hover .discovery-card-title {
    opacity: 1;
    transition: opacity 700ms ease;
}

.discovery-card-icon {
    filter: drop-shadow(
        0 0 2px var(--transparentGrey),
        0 0 2px var(--transparentGrey),
        0 0 2px var(--transparentGrey),
        0 0 2px var(--transparentGrey));
}

.discovery-card-title-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    aspect-ratio: 1/1.5;
    overflow: hidden;
}

.discovery-card-title {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 3%;
    font-weight: 400;
    color: var(--white);
    text-shadow:
        0 0 2px var(--transparentGrey),
        0 0 2px var(--transparentGrey),
        0 0 2px var(--transparentGrey),
        0 0 2px var(--transparentGrey);
    font-size: 1em;
    word-wrap: break-word;
    transition: opacity 350ms ease;
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
    border-radius: 8px;
}

.discovery-card-profile-pic-container {
    width: 100%;
    aspect-ratio: 1/1.5;
    object-fit: cover;
    border-radius: 8px;
    transition-duration: 550ms;
    opacity: 100%;
    z-index: 3;
}

.discovery-card-profile-pic-container:hover {
    opacity: 0%;
    transform: translate3d(20px, 20px, 0);
    transition-duration: 550ms;
}

.discovery-card-profile-pic {
    width: 100%;
    aspect-ratio: 1/1.5;
    object-fit: cover;
    border-radius: 8px;
}

.discovery-card-background-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0 6%;
    z-index: 1;
    background-position: 0% 0%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.discovery-card-cards-inside {
    padding: 0 8%;
    background-position: 0% 0%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.discoveryHeart {
    position: absolute;
    bottom: 6%;
    right: 6%;
    z-index: 3;
    color: var(--defaultGreen);
}

.discoveryDelete {
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    z-index: 3;
    color: rgba(255, 255, 255, 0.32);
    opacity: 0;
    transition-duration: 50ms;
}

.discoveryDelete:hover {
    color: rgba(255, 255, 255, 0.72);
    box-shadow: outset 1px 1px 1px 1px rgba(55, 55, 55, 0.1);
    transition-duration: 50ms;
}

.discovery-card:hover>.discoveryDelete {
    opacity: 100%;
}

@media (max-width: 600px) {
    .discovery-card:hover:before {
        transform: scale(1.03, 1.03);
    }
    .discovery-card:hover>.discovery-card-background {
        background-position: 0% 0%;
    }
    .discovery-card:hover>.discovery-card-background-container {
        background-position: 0% 0%;
    }
    .discovery-card-profile-pic-container:hover {
        transform: translate3d(0px, 0px, 0);
    }
}