:root {
   --color: var(--softPink);
   --size: 7px;
   --time: 1s;
 }
 
 main {
   width: 30vw;
   max-width: 160px;
   aspect-ratio: 1/1;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .dankAssLoader {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 
 .row {
   display: flex;
 }
 
 .arrow {
   width: 0; 
   height: 0;
   margin: 0 calc(var(--size) / -2);
   border-left: var(--size) solid transparent;
   border-right: var(--size) solid transparent;
   border-bottom: calc(var(--size) * 1.8) solid var(--color);
   animation: blink var(--time) infinite;
   filter: drop-shadow(0 0 calc(var(--size) * 1.5) var(--color));
 }
 
 .arrow.down {
   transform: rotate(180deg);
 }
 
 .arrow.outer1 { animation-delay: calc(var(--time) / -18 * 1); }
 .arrow.outer2 { animation-delay: calc(var(--time) / -18 * 2); }
 .arrow.outer3 { animation-delay: calc(var(--time) / -18 * 3); }
 .arrow.outer4 { animation-delay: calc(var(--time) / -18 * 4); }
 .arrow.outer5 { animation-delay: calc(var(--time) / -18 * 5); }
 .arrow.outer6 { animation-delay: calc(var(--time) / -18 * 6); }
 .arrow.outer7 { animation-delay: calc(var(--time) / -18 * 7); }
 .arrow.outer8 { animation-delay: calc(var(--time) / -18 * 8); }
 .arrow.outer9 { animation-delay: calc(var(--time) / -18 * 9); }
 .arrow.outer10 { animation-delay: calc(var(--time) / -18 * 10); }
 .arrow.outer11 { animation-delay: calc(var(--time) / -18 * 11); }
 .arrow.outer12 { animation-delay: calc(var(--time) / -18 * 12); }
 .arrow.outer13 { animation-delay: calc(var(--time) / -18 * 13); }
 .arrow.outer14 { animation-delay: calc(var(--time) / -18 * 14); }
 .arrow.outer15 { animation-delay: calc(var(--time) / -18 * 15); }
 .arrow.outer16 { animation-delay: calc(var(--time) / -18 * 16); }
 .arrow.outer17 { animation-delay: calc(var(--time) / -18 * 17); }
 .arrow.outer18 { animation-delay: calc(var(--time) / -18 * 18); }

 .arrow.inner1 { animation-delay: calc(var(--time) / -6 * 1); }
 .arrow.inner2 { animation-delay: calc(var(--time) / -6 * 2); }
 .arrow.inner3 { animation-delay: calc(var(--time) / -6 * 3); }
 .arrow.inner4 { animation-delay: calc(var(--time) / -6 * 4); }
 .arrow.inner5 { animation-delay: calc(var(--time) / -6 * 5); }
 .arrow.inner6 { animation-delay: calc(var(--time) / -6 * 6); }
 
 @keyframes blink {
   0% { opacity: 0.1; }
   30% { opacity: 1; }
   100% { opacity: 0.1; }
 }
 