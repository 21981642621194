.bottom-nav {
    /* backdrop-filter: blur(16px); */
    position: fixed;
    bottom: 0;
    margin: 0 10px 10px;
    width: calc(100% - 20px);
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 80px;
    z-index: 900;
}

.tab {
    color: #1E1E1E;
    font-weight: bold;
    padding: 8px;
    aspect-ratio: 1/1;
}

.tab-active {
    color: var(--white);
    background-color: var(--softBlack);
    padding: 8px;
    border-radius: 8px;
    aspect-ratio: 1/1;
}