.segmentedControl.segmentedControl {
    display: flex;
}

.selected.selected {
    background-color: var(--balancedCyan);
    border-color: transparent;
    width: 50%;
    border-radius: 24px;
    color: #FAFAFA;
    box-shadow: none;
}

.selected.selected:hover {
    background-color: var(--balancedCyan);
    box-shadow: none;
}

.notSelected.notSelected {
    background-color: transparent;
    border-color: rgba(155, 155, 155, .6);
    width: 50%;
    border-radius: 24px;
    color: var(--balancedCyan);
}

.notSelected.notSelected:hover {
    border-color: var(--balancedCyan);
}