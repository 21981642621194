.discoveryListContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: visible;
    padding: 0 1em
}

.sectionTitle {
    font-family: 'Lora';
    font-size: 1.5em;
    font-weight: 400;
    white-space: nowrap;
    width: fit-content;
    padding:  0 0 2px;
    border-bottom: 5px solid;
    border-image: 
        linear-gradient(to right, 
        var(--defaultGreen) 33.3%,
        var(--flashyPink) 33.3%, 
        var(--flashyPink) 66.7%, 
        var(--handsomeBlue) 66.7%) 5;
}

.discoveryCardsWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 3em;
    column-gap: 2em;
}

.showAllButton {
    display: flex;
    gap: 6px;
    background-color: transparent;
    border: none;
    font-size: 0.8em;
    cursor: pointer;
    border-radius: 8px;
    box-shadow: 
        inset 1px -1px 0px 1px var(--defaultGreen), 
        inset 2px -2px 0px 2px var(--softBlack),
        inset 5px -5px 0px 1px var(--softPink), 
        inset 6px -6px 0px 2px var(--softBlack);
    border: 2px solid var(--softBlack);
    margin: 16px 37.5% 0;
    padding: 8px 0 12px;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
  
.showAllButton:hover {
    background-color: var(--darkBeige);
    box-shadow: 
        inset 1px -1px 0px 1px var(--balancedCyan), 
        inset 2px -2px 0px 2px var(--softBlack),
        inset 5px -5px 0px 1px var(--flashyPink), 
        inset 6px -6px 0px 2px var(--softBlack);
}


@media (min-width: 480px) {
    .discoveryCardsWrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 600px) {
    .discoveryListContainer {
        margin: 0 0.5em;
    }
    .discoveryCardsWrapper {
        row-gap: 1.5em;
        column-gap: 1.5em;
    }
    .showAllButton {
        margin: 16px 25% 0;
        width: 50%;
    }
    .showAllButton:hover {
        background-color: transparent;
    }
}

@media (min-width: 1200px) {
    .discoveryCardsWrapper {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (min-width: 1450px) {
    .discoveryCardsWrapper {
        grid-template-columns: repeat(7, 1fr);
    }
}